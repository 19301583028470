<template>
  <div>
    <load-profile></load-profile>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', resource)">
            <b-button
              variant="primary"
              :to="{ name: 'create-role-permission' }"
              >{{ $t("button.create") }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          @destroy="destroy"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="true"
          :resource="resource"
          :route="route"
        >
          <template #cell(totalUsers)="data">
            {{
              $tc("field.personCount", data.item.totalUsers, {
                count: data.item.totalUsers,
              })
            }}
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap" v-if="!data.item.isDefault">
              <b-button
                v-if="$can('update', resource)"
                :to="{
                  name: `update-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="$can('delete', resource)"
                v-b-tooltip.hover
                :title="$t('button.delete')"
                variant="danger"
                pill
                size="sm"
                @click="
                  $refs.table.destroy(data.item.id, data.index, data.item)
                "
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-button
                v-if="$can('update', resource)"
                v-b-tooltip.hover
                :title="$t('general.defaultRoleRule')"
                variant="secondary"
                pill
                size="sm"
              >
                <feather-icon icon="LockIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";

const RoleRepository = Repository.get("role");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
    };
  },
  mounted() {
    this.getData(this.params.page);
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      RoleRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(id) {
      RoleRepository.destroy(id)
        .then((response) => {
          this.getData();
        })
        .catch((error) => {});
    },
  },
  setup() {
    const fields = [...tableFields];
    const searchFields = [...searchInputs];
    const resource = "role-permission";
    const route = "role-permission";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
